<template>
  <div  v-loading="loading" element-loading-text="数据较多，拼命加载中..." class="scheduling-container common-padding">
    <breadcrumb :BreadcrumbData="BreadcrumbData" class="common-noMarginLeft"></breadcrumb>
    <div class="common-screen-container common-noMarginLeft">
      <div class="common-input-container">
        <span>客服员工:</span>
        <el-input
          class="common-screen-input"
          placeholder="请输入"
          v-model="params.keyword"
        ></el-input>
      </div>
      <div class="common-input-container">
        <span>所属部门:</span>
        <el-cascader
          class="common-screen-input"
          v-model="params.departId"
          :key="updateCascader"
          :options="selectDepart"
          @change="commonFun"
          filterable
          clearable
          :props="{
            label: 'name',
            children: 'child',
            value: 'id',
            emitPath: false,
          }"
        ></el-cascader>
      </div>
      <el-button
        class="common-screen-btn"
        type="primary"
        @click="
          () => {
            commonFun();
          }
        "
        >查 询</el-button
      >
      <el-button class="common-screen-btn" plain @click="() => resetBtn()"
        >重 置</el-button
      >
    </div>
    
    <el-button class="common-screen-btn common-noMarginLeft" type="primary" @click="() => { SchedulingShow() }" >批量排班</el-button>
    <div class="upload-userIcon">
      <el-button class="common-screen-btn" type="primary" style="margin-left: 0">导入数据</el-button>
      <input class="common-upload-input" type="file" @input="(e) => chooseFile(e, 'xlsx')" />
    </div>
    <el-button class="common-screen-btn" @click="() => download()">导出</el-button>
    <!-- <el-button class="common-screen-btn" style="margin-left: 0" type="text" @click="() => downloadTemplate()">模板下载</el-button> -->
    <div class="tableScreen">
      <el-date-picker
        v-if="tableRadio == '按周'"
        v-model="tableDate"
        :picker-options="{ firstDayOfWeek: 1 }"
        type="week"
        format="yyyy 第 WW 周"
        placeholder="选择周"
        @change="(e) => onchangeDate(e, 'W')"
      >
      </el-date-picker>
      <el-date-picker
        v-if="tableRadio == '按月'"
        v-model="tableDate"
        type="month"
        @change="(e) => onchangeDate(e, 'M')"
        placeholder="选择月"
      >
      </el-date-picker>
      <el-radio-group
        class="date"
        v-model="tableRadio"
        @change="isMonthWeekFun"
      >
        <el-radio-button label="按周"></el-radio-button>
        <el-radio-button label="按月"></el-radio-button>
      </el-radio-group>
    </div>
    <el-table
      v-if="tableRadio == '按月'"
      class="common-table"
      :data="tableData"
    >
      <el-table-column width="140px" label="员工">
        <template slot-scope="scope">
          <!-- <div class="nameIcon">
            <span>
              {{ scope.row.nickName.slice(scope.row.nickName.length - 2) }}
            </span>
          </div> -->
          <span>
            {{ scope.row.nickName }}
            <br />
            {{ scope.row.phone }}
          </span>
        </template>
      </el-table-column>
      <el-table-column
        v-for="(item, mIndex) in arrWeekMonth"
        :key="item.date"
        :label="`${item.week}\n(${item.date})`"
        width="120px"
      >
        <template slot-scope="scope">
          <div>
            <el-dropdown v-if="isColorGrey(item.date)">
              <el-button type="text">{{
                scope.row.schedulingList[mIndex]
              }}</el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  v-for="(itemTwo, indexTwo) in freQList"
                  :key="indexTwo"
                >
                  <div
                    @click="
                      () => {
                        serviceScheduling({
                          frequencyId: itemTwo.id,
                          adminId: scope.row.id,
                          date: item.date
                        });
                      }
                    "
                  >
                    {{ itemTwo.frequencyName }}
                  </div>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <el-button v-else type="text" style="color: #999">{{
              scope.row.schedulingList[mIndex]
            }}</el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-table
      v-if="tableRadio == '按周'"
      class="common-table"
      :fit="true"
      :data="tableData"
    >
      <el-table-column label="员工">
        <template slot-scope="scope">
          <!-- <div class="nameIcon">
            <span>
              {{ scope.row.nickName.slice(scope.row.nickName.length - 2) }}
            </span>
          </div> -->
          <div style="display: inline-block">
            {{ scope.row.nickName }}
            <br />
            {{ scope.row.phone }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        v-for="(item, wIndex) in arrWeekDate"
        :key="item.week + '' + item.date"
        :label="`${item.week}\n(${item.date})`"
      >
        <template slot-scope="scope">
          <div>
            <el-dropdown v-if="isColorGrey(item.date)">
              <el-button type="text">{{
                scope.row.schedulingList[wIndex]
              }}</el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  v-for="(itemTwo, indexTwo) in freQList"
                  :key="indexTwo"
                >
                  <div
                    @click="
                      () => {
                        serviceScheduling({
                          frequencyId: itemTwo.id,
                          adminId: scope.row.id,
                          date: item.date
                        });
                      }
                    "
                  >
                    {{ itemTwo.frequencyName }}
                  </div>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <el-button v-else type="text" style="color: #999">{{
              scope.row.schedulingList[wIndex]
            }}</el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="(e) => pageFun(e, 'pageSize')"
      @current-change="(e) => pageFun(e)"
      style="text-align: center"
      :page-sizes="pagination.pageSizes"
      :page-size="params.pageSize"
      :current-page="params.page"
      layout="total, prev, pager, next,sizes, jumper"
      :total="pagination.total"
    >
    </el-pagination>
    <el-dialog v-dialogDrag title="批量排班" :append-to-body="true" :visible.sync="centerDialogVisible" width="520px" >
      <div>
        <el-form :model="ruleFormAdd" :rules="rules" ref="ruleFormCom" label-width="100px" class="demo-ruleForm" >
          <el-form-item label="班次" prop="frequencyId">
            <el-select class="dialog-input" v-model="ruleFormAdd.frequencyId" placeholder="请选择班次" filterable >
              <el-option  v-for="itemSelectt in freQList" :key="itemSelectt.id" :label="itemSelectt.frequencyName" :value="itemSelectt.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="时间范围" prop="startEnd">
            <el-date-picker class="dialog-input" v-model="ruleFormAdd.startEnd" type="daterange" range-separator="~" start-placeholder="开始日期" end-placeholder="结束日期" >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="员工名称" prop="list">
            <el-select class="dialog-input" v-model="ruleFormAdd.list" @change="(val) => staffChange(val)" filterable clearable placeholder="请选择员工" >
              <el-option v-for="item in allSelect" :key="item.id" :label="item.nickName" :value="item.id" ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
              <div class="orderShop-old">
                <div v-for="(item, index) in staffList" :key="index" class="oldItem">{{item.nickName}}<span @click="() => deleteStaff(index)" class="close">×</span></div>
              </div>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          class="common-screen-btn"
          @click="centerDialogVisible = false"
          >取 消</el-button
        >
        <el-button
          class="common-screen-btn"
          type="primary"
          @click="() => {submitForm()}">确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import Breadcrumb from "../../components/common/breadcrumb.vue";
import { getWeekMonthInfo, getWeekDateInfo, Config, DateTransform } from "../../utils/index.js";
import { commonDepartAll } from "../../service/common.js";
import {
  schedulingList,
  serviceScheduling,
  frequencyList,
  serScheAll2,
  uploadScheduling
} from "../../service/service.js";
export default {
  components: {
    Breadcrumb
  },
  data() {
    return {
      loading: false,
      updateCascader: 1, // 用于重新渲染练级菜单，防止警告
      tableDate: new Date(), // 默认月份 new Data()
      arrWeekMonth: getWeekMonthInfo(new Date()),
      arrWeekDate: getWeekDateInfo(new Date()),
      BreadcrumbData: [
        { title: "首页", isLink: true, url: "/index" },
        { title: "客服", isLink: false },
        { title: "客服排班", isLink: false }
      ],
      ruleFormAdd: {
        list: '',
        startEnd: [],
        frequencyId: ''
      },
      staffList: [],
      batchForm: {
        startEndDateAll: []
      },
      rules: {
        list: [{ required: true, message: "请选择员工", trigger: "change" }],
        startEnd: [{ required: true, message: "请选择时间范围", trigger: "change" }],
        frequencyId: [{ required: true, message: "请选择班次", trigger: "change" }]
      },
      pagination: {
        total: 0,
        pageSizes: [5, 10, 20, 50, 100]
      },
      params: {
        page: 1,
        pageSize: 10,
        startDate: getWeekDateInfo(new Date())[0]["date"],
        endDate: getWeekDateInfo(new Date())[
          getWeekDateInfo(new Date()).length - 1
        ]["date"]
      },
      selectDepart: [],
      allSelect: [], // 所有员工的下拉
      freQList: [],
      tableRowId: "", // 客户id用于编辑/查看
      centerDialogVisible: false, // 修改密码员工弹窗
      rolesCompany: this.$sto.get(Config.constants.userInfo).roles_company.filter((item)=>{return item.id != 0}), // 所属公司下拉
      tableRadio: "按周",
      roleSelect: "",
      tableData: []
    };
  },
  created() {
    if (this.rolesCompany && this.rolesCompany.length) {
      this.params.companyId = this.rolesCompany[0].id;
      this.commonDepartAll(this.rolesCompany[0].id);
    }
    this.schedulingAll();
    this.schedulingList();
    this.frequencyList();
  },
  methods: {
    deleteStaff(i) {
      let staffList = [...this.staffList]
      staffList = staffList.filter((item, index) => {
        if(item) {
          return index != i
        }
      })
      this.staffList = staffList
    },
    staffChange (e) {
      let allSelect = [...this.allSelect]
      let staffList = [...this.staffList]
      let isExistence = false
      staffList.map((item) => {
        if(item.id == e) {
          isExistence = true
        }
      })
      if (isExistence) { return this.$message.error("已选择") }
      if (e) {
        staffList.push(...allSelect.filter((item) => {return item.id == e}))
      }
      this.staffList = staffList
    },
    SchedulingShow() { // 批量排版清空处理
      this.ruleFormAdd = {
        list: '',
        startEnd: [],
        frequencyId: ''
      }
      this.staffList = []
      if (this.$refs["ruleFormCom"]) {this.$refs["ruleFormCom"].resetFields();}
      this.centerDialogVisible = true
    },
    commonFun() {
      this.params.page = 1;
      this.params.pageSize = 10;
      this.schedulingList();
    },
    async chooseFile(e, type) {
      // 反馈上传文件
      // console.log(type);
      let file = e.target.files[0];
      let format = file.name.split(".")[file.name.split(".").length - 1];
      if (type == "xlsx") {
        if (format != "xls" && format != "xlsx") {
          this.$message.error("仅支持xls、xlsx格式文件");
          e.target.value = "";
          return;
        }
      }
      this.loading = true
      await uploadScheduling({ file: file });
      this.loading = false
      this.$message.success("导入成功");
      this.schedulingList();
    },
    downloadTemplate() {
      // 下载模板-暂时写死
      window.location.href =
        "https://sy-my.oss-cn-zhangjiakou.aliyuncs.com/sy/file/%E6%89%B9%E9%87%8F%E6%A8%A1%E6%9D%BF/%E5%AE%A2%E6%9C%8D%E6%95%B0%E6%8D%AE%E4%B8%8A%E4%BC%A0%E6%A8%A1%E6%9D%BF2021-04-06tk.xlsx";
    },
    download() {
      let params = { ...this.params };
      delete params.page;
      delete params.pageSize;
      let url = Config.api.url + "/customerService/scheduling/schedulingExport";
      let paramArr = [];
      for (let item in params) {
        if (params[item]) {
          paramArr.push(item + "=" + params[item]);
        }
      }
      url = url + "?" + paramArr.join("&");

      window.location.href = url;
    },
    submitForm() {
      // 批量排班提交
      this.$refs["ruleFormCom"].validate(async (valid) => {
        if (valid) {
          let ruleFormAdd = {...this.ruleFormAdd}
          let staffList = [...this.staffList]
          if (ruleFormAdd.startEnd) {
            ruleFormAdd.startDate = DateTransform(ruleFormAdd.startEnd[0])
            ruleFormAdd.endDate = DateTransform(ruleFormAdd.startEnd[1])
            delete ruleFormAdd.startEnd
          }
          if (staffList && staffList.length) {
            let ids = []
            staffList.map((item) => {
              ids.push(item.id)
            })
            ruleFormAdd.list = ids.join(',')
          } else {
            this.$message.error("员工不能为空")
          }
          // if (ruleFormAdd.list) {
          //   ruleFormAdd.list = ruleFormAdd.list.join(',')
          // }
          await serScheAll2(ruleFormAdd);
          this.$message.success("排班成功");
          this.centerDialogVisible = false;
          this.schedulingList();
        } else {
          return false;
        }
      });
    },
    async frequencyList() {
      let params = { ...this.params };
      let resData = (
        await frequencyList({
          departId: params.departId || "",
          companyId: params.companyId || ""
        })
      ).data;
      this.freQList = resData.list;
    },
    async serviceScheduling(params) {
      this.loading = true;
      // 客服排班
      await serviceScheduling(params);
      this.$message.success("排班成功");
      this.schedulingList();
      this.loading = false;
    },
    resetBtn() {
      // 重置
      this.params = {
        page: 1,
        pageSize: 10,
        startDate: getWeekDateInfo(new Date())[0]["date"],
        endDate: getWeekDateInfo(new Date())[
          getWeekDateInfo(new Date()).length - 1
        ]["date"]
      };
      if (this.rolesCompany && this.rolesCompany.length) {
        this.params.companyId = this.rolesCompany[0].id;
        this.commonDepartAll(this.rolesCompany[0].id);
      }
      this.arrWeekMonth = getWeekMonthInfo(new Date())
      this.arrWeekDate = getWeekDateInfo(new Date())
      this.tableDate = new Date()
      this.selectDepart = [];
      this.updateCascader++;
      this.tableRadio = "按周";
      this.schedulingList();
    },
    isColorGrey(date) {
      // 判断排版是否置灰
      let nowDate = new Date().getTime(); // 当前时间按戳
      let dayDate = new Date(date).getTime(); // 传入的时间戳
      return nowDate < dayDate + 1000 * 60 * 60 * 24;
    },
    async schedulingList() {
      this.loading = true;
      let params = { ...this.params };
      if (!params.departId) {
        params.departId = ''
      }
      let resData = (await schedulingList(params)).data;
      this.pagination.total = resData.total;
      this.pagination.pageSize = resData.size;
      this.tableData = resData.records;
      this.frequencyList();
      this.loading = false;
    },
    async schedulingAll() {
      // 批量排班--获取员工下拉
      let resData = (await schedulingList({ page: 0, pageSize: 0, companyId: this.params.companyId })).data;
      this.allSelect = resData.records;
    },
    companyFun(id) {
      //选择公司筛选 ---
      this.params.departId = "";
      this.params.page = 1
      this.schedulingList();
      this.schedulingAll();
      this.commonDepartAll(id);
    },
    async commonDepartAll(id) {
      // 部门 下拉
      let resData = (await commonDepartAll({}, id)).data;
      this.selectDepart = resData ? resData : [];
      this.updateCascader++;
    },
    pageFun(e, type) {
      // 分页
      if (type == "pageSize") {
        this.params.pageSize = e;
      } else {
        this.params.page = e;
      }
      this.schedulingList();
    },
    isMonthWeekFun(e) {
      // 按月/按周按钮
      let params = { ...this.params };
      if (e == "按月") {
        let arrWeekMonth = [...this.arrWeekMonth];
        params.startDate = arrWeekMonth[0]["date"];
        params.endDate = arrWeekMonth[arrWeekMonth.length - 1]["date"];
      }
      if (e == "按周") {
        let arrWeekDate = [...this.arrWeekDate];
        params.startDate = arrWeekDate[0]["date"];
        params.endDate = arrWeekDate[arrWeekDate.length - 1]["date"];
      }
      this.params = params;
      this.schedulingList();
    },
    onchangeDate(e, type) {
      // type  -- M月 -- W周
      let params = { ...this.params };
      if (type == "M") {
        let arrWeekMonth;
        arrWeekMonth = getWeekMonthInfo(e);
        params.startDate = arrWeekMonth[0]["date"];
        
        params.endDate = arrWeekMonth[arrWeekMonth.length - 1]["date"];
        this.arrWeekMonth = arrWeekMonth;
      }
      if (type == "W") {
        let arrWeekDate;
        arrWeekDate = getWeekDateInfo(e);
        params.startDate = arrWeekDate[0]["date"];
        params.endDate = arrWeekDate[arrWeekDate.length - 1]["date"];
        this.arrWeekDate = arrWeekDate;
      }
      this.params = params;
      this.schedulingList();
    }
  }
};
</script>
<style lang="less" scoped>
.orderShop-old {
  width: 320px;
  height: 160px;
  border-radius: 4px;
  border: #DCDFE6 solid 1px;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 0 0 4px 0;
  .oldItem {
    display: inline-block;
    padding: 0 8px 0 10px;
    line-height: 28px;
    color: #fff;
    background-color: #1890FF;
    border-radius: 4px;
    margin: 2px 0 0 8px;
    .close {
      color: #fff;
      margin-left: 8px;
      cursor: pointer;
      font-size: 18px;
    }
  }
}
.scheduling-container {
  text-align: left;
  .upload-userIcon {
    padding: 0;
    position: relative;
    display: inline-block;
    margin-left: 15px;
  }
  .tableScreen {
    text-align: right;
    margin-top: 24px;
    .date {
      margin-left: 16px;
    }
  }
  .nameIcon {
    width: 40px;
    height: 40px;
    display: inline-block;
    color: #f56a00;
    background-color: #fde3cf;
    border-radius: 50%;
    text-align: center;
    line-height: 40px;
    vertical-align: top;
    margin-right: 10px;
  }
}
.dialog-input {
  width: 320px !important;
}
.dialog-item-input {
  width: 180px !important;
}
/deep/ .el-table .cell {
  white-space: pre-line;
}
</style>
